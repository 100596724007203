.absences{
  max-width: 70%;
  margin: 0 auto;
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.absences .modal-header i{
  margin-right: 20px;
  
}

.absences .modal-body{
  margin: 10px;
  position: inherit;
}

.absences .card{
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.absences .status h5 {
  float: left !important
}

.absences .progress{
  width: 80% !important;
  margin-right: 10px;
}

.absences .card-body .total{
  float: right;
  margin-top: 10px;
  font-size: 12px;
  width: 17%;
}

.absences .status .badge{
  float: right !important;
  padding: 5px 10px;
  margin-top: 5px;
  opacity: 0.8
}

.absences .btn-default{
  background: rgba(224, 224, 224, 0.77);
  color: var(--font);
}

.absences .btn-default i{
  color: #797979;
}

.absences .btn-default:hover{
  background: rgba(78, 78, 78, 0.77);
  color: white
}

.absences .btn-default:hover i{
  
  color: white
}

.absences .new-absence {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.absences .new-absence .add {
  border: 2px solid #dc3545;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.absences .new-absence .add i {
  font-size: 20px;
  color: #dc3545;
}

.absences .new-absence .add span {
  margin-left: 20px;
  color: #dc3545;
  font-weight: 500;
}

.absences .new-absence .add:hover {
  background-color: #dc3545;
  color: white;
  cursor: pointer;
}

.absences .new-absence .add:hover i {
  color: white;
}

.absences .new-absence .add:hover span {
  color: white;
}

.absences .new-absence .year-selector{
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 20px;
  align-items: center;
}

.absences .new-absence .year-selector input{
  padding: 11px 20px;
  width: 120px;
}

.icon-calendar {
	height: auto;
  width: 35%;
	margin-bottom: 10px;
  margin-right: 10px;
  text-align: center;
  float: left;
}

.calendar-title {
	font-size: 15px;
	font-weight: bold;
	text-shadow: 0 5px #15181f;
  
}

#weekday {
	background: #d9383c;
	border-radius: 15px 15px 0 0;
	color: #f9f9f9;
	font-size: 10px;
	line-height: 26px;
	position: relative;
	text-transform: lowercase;
	-webkit-border-radius: 15px 15px 0 0;
	-moz-border-radius: 15px 15px 0 0;
	-ms-border-radius: 15px 15px 0 0;
	-o-border-radius: 15px 15px 0 0;
}

#weekday:before,
#weekday:after {
	background: #f9f9f9;
	border-radius: 50%;
	content: "";
	height: 4px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 5px;
	z-index: 1;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

#weekday:before { left: 15%; }
#weekday:after { right: 15%; }

#day {
	background: #f9f9f9;
	border-radius: 0 0 15px 15px;
	color: #15181f;
	font-size: 25px;
	font-weight: bold;
	height: 40px;
	line-height: 35px;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	-webkit-border-radius: 0 0 15px 15px;
	-moz-border-radius: 0 0 15px 15px;
	-ms-border-radius: 0 0 15px 15px;
	-o-border-radius: 0 0 15px 15px;
}


.fa-arrow-right{
  float: left; 
  margin-right: 10px;
  margin-top: 30px;
}

.badge{
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.badge i{
  color: white !important;
  margin-right: 10px !important;
}

.badge span{
  font-size: 12px !important;
}

.absence-item .badge{
  padding: 5px 10px !important;
  margin: 0 auto;
}


.absence-item .Aceptado{
  background: #198754;
  color: #198754;
}

.absence-item .Pendiente{
  background: #ffc107;
  color: #ffc107;
}

.absence-item .Rechazado{
  background: #dc3545;
  color: #dc3545;
}


.absences .grayscale{
  filter: grayscale(40%);
  -webkit-filter: grayscale(40%);
  -moz-filter: grayscale(40%);
  -ms-filter: grayscale(40%);
  -o-filter: grayscale(40%);
}

#absences-modal.disabled{
  display: none;
}

#absences-modal{
  position: absolute;
  background: #00000059;
  animation-duration: inherit;
}

#absences-modal .modal-header i{
  margin-right: 15px;
  font-size: 22px;
}

#absences-modal .react-datepicker-wrapper {
  width: 100%;
}

#absences-modal .datePicker-input{
  border: 1px solid #cacaca !important;
  padding: 0.5rem 0.75rem;
  width: 100%;
}


#absences-modal .react-datepicker__close-icon {
  right: 2%;;
}

#absences-modal .react-datepicker__close-icon::after {
  background: #dc3545;
  width: 25px;
  
  font-size: 20px;
}

#absences-modal .submit{
  width: fit-content;
}

@media only screen and (max-width: 1600px) {
  .absences{
    max-width: 95%;
  }

  .absences .progress{
    width: 78% !important;
    margin-right: 5px;
  }

}

@media only screen and (max-width: 767px) {
  .absences{
    max-width: 100%;
  }

  .absences .modal-body{
    margin:0;
  }

  .absences .btn-default{
    background: white;
    border: 2px solid #797979;
    padding: 12px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    font-weight: 500;
}
  .new-absence{
    width: auto !important;
    font-size: 14px;
    text-align: center;
    margin-top: 25px;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
    margin-bottom: 30px !important;
}

  .list .d-none{
    display: block !important; 
  }

  .absence-item .card-title{
    text-align: center;
  }

  .absence-item .col-md-3{
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .absence-item .col-md-2 .card-text{
    margin-top: -20px !important;
  }

  .absence-item .col-md-2 .badge{
    text-align: center;
  }

  .absence-item .card-text{
    margin-top: 20px;
    text-align: center;
  }

  .icon-calendar{
    width: 70px;
  }

  .absence .float-start{
    float: left !important
  }
}

