.Alert{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  width: 50%;
  margin: 10% auto;
  
}

.Alert img{
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 10px;
  opacity: 0.5;
}

.Alert .modal-body{
 position: relative;
 margin-top: -15px;
 text-align: center;
 padding: 25px 80px;

}

@media only screen and (max-width: 767px) {

  .Alert{
    width: 100%;
    margin: 5% auto 0 auto;
  }

  .Alert .modal-body{
    padding: 15% 10% 10% 10%;
  }

  .Alert .row p{
    margin-bottom: 25px;
  }

  .Alert .btn-danger{
    margin-bottom: 0;
  }
  

}


