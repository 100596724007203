body{
  background: white !important;
}

.reports {
  padding: 2% 3.5%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reports iframe {
  width: 100%;
  height: 710px;
  
  
}