.input{
  max-width: 700px;
  height: auto;
  width: auto;
  margin: auto;
}

.input .modal{
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}


.btn-close{
  font-size: 20px;
}

.input .btn{
  margin-left: 10px;
  margin-bottom: 15px;
  color: white;
  font-style: bold;
}

.input .btn-success {
  padding: 0.35rem 3rem;
  margin-left: 0;
}

.input .btn-warning {
  color: white;
  padding: 0.35rem 2.2rem;
}

.input .btn-secondary {
  float: right;
}

.input .btn-outline-warning {
  color: #ffc107;
  float: none;
  margin-bottom: 0;
}

.input .btn-outline-warning:hover {
  color: white;
}

.disabled{
  background-color: #bfbfbf !important;
  border: 1px solid #e9e9e9 !important;
}

.button-group {
  position: relative;
  float: left;
}

.hidden-button{
  display: contents;
}

.outer {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: fit-content;
  width: auto;
}

.inner {
  position: absolute;
  right: 10%;
  color: white;
  width: 20% !important;
  
}

.fa-chevron-down{
  color: white;
}

.btn-outline-success{
  color: #198754 !important;
  background: #19875446;
}

.btn-outline-success:hover{
  background: #19875446;
}

.input textarea{
  width: 100%;
  margin-bottom: 20px;
}


@media only screen and (max-width: 767px) {

  .input .btn{
    width: 92%;
    margin-left: 4%; 
    font-size: 20px;
    margin-bottom: 5%;
  }

  .button-group {
    float: none ;
  }

  .float-start {
    float: none !important;
  }

  .float-start .btn-danger{
    margin-left: 4% !important;
  }

  .float-end{
    float: none !important;
  }

  .outer {
    position: inherit;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: fit-content;
  }
  
  .input .btn-secondary{
    width: 60%;
    margin: 5% 20% 1% 20%;
    float: none;
  }
}