.workerList{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  width: 95%;
  margin: 0 auto;
}

.workerList .fa-list-ul{
  margin-right: 20px;
  font-size: 22px;
}

.workerList .nav-tabs{
  margin-right: 15px
}

.workerList .nav-link{
  color: #495057;
}

.workerList .nav-link.active{
  background: #f7f7f7;
  border-bottom: 2px solid #f7f7f7;
}

.workerList .list{
  border: 1px solid #dee2e6;
  background: #f7f7f7;
  border-top: none;
  padding: 30px 20px ;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
 
}

.workerList .worker{
  background: white;
  margin: 10px 0;
  width: 100%;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  margin-bottom: 10px;

}

.workerList img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
  object-fit: cover;
  
}

.workerList p{
 text-overflow: ellipsis;
 margin: 0;
}

.workerList p.Presencial{
  color: #1d9b60;
 }

.workerList p.Teletrabajo{
  color: #17a2b8;
}

.workerList p.Desconectado{
  color: #dc3545;
}

.workerList p.Viaje{
  color: #ffc107;
}

.workerList .btn-default {
  background:#e67015 !important;
  color: white;
  width: 100%;
  margin-top: 10px;
  
}

.workerList .select-year {
  background: #e9ebed;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: 1px solid #ced4da;
  border-left: 0;
  color: #373839;
}

@media only screen and (max-width: 767px) {
  .float-start{
    float: left !important;
  }
  .float-end{
    float: right !important;
  }
}
  
