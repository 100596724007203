
.receips .col-md-7{
  padding: 40px 20px 0 50px;
  transition: 0.8s;
  -webkit-transition: 0.8s;
  -moz-transition: 0.8s;
  -ms-transition: 0.8s;
  -o-transition: 0.8s;
}

.receips .col-md-7.active{
  width: 100%;
}

.receips .container-fluid>.row{
  flex-wrap: nowrap;
  overflow-x: hidden;
}

.receips .fa-arrow-left{
  cursor: pointer;
  font-size: 22px;
}

.receips .fa-arrow-left:hover{
  font-size: 24px;
}

.receips .btn-primary{
  background: #284b63;
  padding: 8px 10px;
  border: none;
}

.receips .list{
  overflow-y: auto;
  height: 65vh;
} 

.receips .image-list {
  width: 40px;
  max-height: 60px;
  object-fit: cover;
  margin-left: 40px;
  box-shadow: 0px 2px 2px 1px rgba(48, 48, 48, 0.313)
}


.receips .rdt_Table{
  background: #fdf8f3;
  margin-bottom: 40px;
  width: 98% !important
}

.receips .rdt_TableHead{
  display: flex !important;
  
}

.receips .rdt_TableHeadRow{
  background: #fdf8f3;
}

.receips .rdt_TableRow{
  
  height: 120px;
  margin-top: 10px;
  font-size: 15px;
  border: none !important;
  box-shadow: 11px 16px 10px -12px rgba(0,0,0,0.1);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.receips .badge{
  padding: 5px 15px;
}

.badge.comida{
  background: #588157;
  border: 2px solid #588157;
}

.badge.alojamiento{
  background: #6096ba;
  border: 2px solid #6096ba;
}

.badge.transporte{
  background: #fcbf49;
  border: 2px solid #fcbf49;
}

.badge.otros{
  background: #7f7f7f;
  border: 2px solid #7f7f7f;
}


.receips .badge:hover{
  color: white;
}

.receips .visualize{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86vh;
  position: relative;
  background: white;
}

.receips .visualize.active{
  margin-left: 41.66666667%;
  transition: 0.8s;
  -webkit-transition: 0.8s;
  -moz-transition: 0.8s;
  -ms-transition: 0.8s;
  -o-transition: 0.8s;
}

.receips .visualize img{
  height: 70vh;
  box-shadow: 8px 16px 20px -18px rgba(0, 0, 0, 0.43); 
}

.receips .visualize i{
  position: absolute;
  cursor: pointer;
  top: 50px;
  left: 40px;
  padding: 10px 15px;
  font-size: 22px;
}

.receips .visualize i:hover{
  color: white;
  background: rgba(65, 65, 65, 0.2);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}


@media only screen and (max-width: 767px) {
.receips .col-md-7{
  padding: 20px;
}

.receips .rdt_TableCell {
  width: 140px !important;
} 

.receips .visualize.active{
  margin-left: -100%;
}

.receips .visualize i{
  
  top: 20px;
  
  padding: 10px 15px;
  font-size: 22px;
  left: 85%
}

}