.travel{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  max-width: 60%;
  margin: 0 auto;
}

.travel .modal-body{
  padding: 1rem 1rem 0 1rem;
}

.travel input{
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.travel .input-group {
  flex-wrap: initial;
}

.travel .input-group-prepend{
  width: 40%;
  border: 1px solid #dee2e6;
  background: #aeaeae;
}

.travel span {
  color: white;
  background: none;
  border: none;
  margin-top: 2px;
  font-weight: 600;
}

.travel .disabled{
  background: #ebebeb;
}

.travel .react-datepicker-wrapper{
  margin-left: -4px !important;
  
}

.travel input{
  border: 1px solid #dee2e6 !important;
  padding: 0.5rem 0.75rem;
  border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.react-datepicker__day--in-selecting-range:hover{
  background: rgb(31, 10, 2);
}

.travel .col-md-7{
  padding: 0 5%;
  border-left: 1px solid #e4e4e4;
}

.travel .col-md-7 .days-selected p {
  float: left;
  width: 45%;
}

@media only screen and (max-width: 767px) {
  .travel{
    max-width: 100%;
  }

  .travel .react-datepicker-wrapper{
    margin-left: -2px !important;
 }

 .travel input {
  
  width: 98%;
 }

  .travel .col-md-7{
    border-left: 0;
    margin-top: 20px;
  }

  .travel .days-selected{
    margin-top: 20px;
  
  }

  .modal-footer p {
    margin: 0;
  }

}