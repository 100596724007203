.absencesReport{
  max-width: 70%;
  margin: 0 auto;
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.absencesReport .modal-header i{
  margin-right: 20px;
  
}

.absencesReport .modal-body{
  margin: 10px;
  position: inherit;
}

.absencesReport .avatar{
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.absencesReport .progress{
  width: 100% !important;
  margin-top: 0.5rem;
  margin-bottom:  0.5rem;
}

.absencesReport .modal-body .userData{
  padding-left: 40px;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow: hidden; /* Oculta cualquier contenido que exceda el ancho del div */
  text-overflow: ellipsis;
 
}


@media only screen and (max-width: 1600px) {
  .absencesReport{
    max-width: 90%;
  }

  .absencesReport .modal-header i{
    margin-right: 10px;
  }

  

}

@media only screen and (max-width: 767px) {
  .absencesReport{
    width: 90%;
  }

  .modal-body .header{
    display: none;
  }

  .absencesReport .modal-body .userData{
    padding-left: 0;
    margin-bottom: 15px;
    text-align: center;
  }

  .absencesReport hr:first-child{
    display: none;
  }
}

