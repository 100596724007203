.workerStatus{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.active-user, .disconnect-user, .trip-user{
  border-right: 1px solid rgba(177, 177, 177, 0.487);
  height: 75vh;
  overflow: auto;
}

.rdt_Table{
  width: 95% !important;

}

.rdt_TableHead {
  display: none !important;
}

li::marker{
  font-size: 30px;
}

li.Presencial::marker{
  color: #04b604;
}

li.Teletrabajo::marker{
  color: #17a2b8;
}

li.Desconectado::marker{
  color:#c90c0c;
}

li.Vacaciones::marker{
  color: rgb(118, 0, 118);
}

li.Viaje::marker{
  color: #ffc107;
}

.workerStatus img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  object-fit: cover;
  top: 10px;
  left:  45px;
  position: absolute;
}

@media only screen and (max-width: 1500px) {
  .active-user, .disconnect-user, .trip-user{
    height: 70vh;
  }

  .workerStatus img{
    width: 40px;
    height: 40px;
    top: 15px;
    left: 35px;
  }
  
}


@media only screen and (max-width: 767px) {
  .modal-route {
    padding: 3%;
    overflow: scroll;
  }

  .modal-route-content{
    animation: slideInUp;
    /*animation: fadeInDown;
    */animation-duration: 0.4s;
  } 

  .active-user, .disconnect-user, .trip-user{
    height: auto;
  }

  .active-user,
  .disconect-user {
    height: auto !important;
  }

  .worker-status .modal-route .col-md-4 {
    margin-bottom: 40px;
  }


}