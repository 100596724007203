.output{
  background-color: white;
  height: auto;
  width: auto;
  margin: auto;
  max-width: 700px;
  animation: slideInDown;
  animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius:15px !important;
  -webkit-border-radius:15px !important;
  -moz-border-radius:15px !important;
  -ms-border-radius:15px !important;
  -o-border-radius:15px !important;
}

.output .modal{
  border-radius:15px !important;
  -webkit-border-radius:15px !important;
  -moz-border-radius:15px !important;
  -ms-border-radius:15px !important;
  -o-border-radius:15px !important;
  -webkit-border-radius: 15px;
}

.btn-close{
  font-size: 20px;
}

.output .modal-body{
 padding: 1rem;
}

.output textarea {
  width: 100%;
}

.output .modal-footer {
  display: flow-root;
  padding: 0.75rem;
}

.output .btn-danger, .btn-secondary, .btn-outline-warning {
  float: right;
}

.output .btn-outline-danger{
  margin-left: 0;
}


@media only screen and (max-width: 767px) {

  .output .modal-footer {
    display: inline-table;
    width: 100%;
  }

  .output .btn-outline-warning{
    width: 99%;
  }
  

  

}