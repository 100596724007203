.pdfViewer{
  position: fixed;
  display: flex;
  align-items: start;
  justify-content: center;
  top: -6vh;
  width: 100vw;
  height: 106vh;
  background: white;
  z-index: 10;
  object-fit: cover;
}


.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  position: fixed;
  bottom: 25px;
  width: 100%;
  z-index: 12;
}

.buttons-container .btn-success{
  width: 100px;
}

#viewer-modal.disabled{
  display: none;
}

#viewer-modal{
  position: absolute;
  display: inline-block;
  background: #00000059;
  top: 50px;
  animation-duration: inherit;
  z-index: 9999 !important;
}

#viewer-modal .modal-content{
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.receip-content{
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

#viewer-modal .modal-header i{
  margin-right: 10px;
  font-size: 25px;
}

#viewer-modal .react-datepicker-wrapper{
  width: 100%;
}

#viewer-modal .react-datepicker-wrapper input{
  width: 100%;
  padding: 7px 10px;
  border: 1px solid #ced4da;
}

#receip-modal .submit{
  width: fit-content;
}


@media only screen and (max-width: 767px) {

  .pdfViewer{align-items: flex-start; top: 0;  }

  object{
    
    height: 80vh !important;
  
  }

  #viewer-modal{
    height: auto;
    position: fixed;
    min-height: 100vh;
  }
  
  #viewer-modal .react-datepicker {
    display: flex;
  }
}