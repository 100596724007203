:root {
  --background: #fdf8f3;
  --header: #e67015;
  --card: white;
  --cardTitle: rgb(65, 65, 65);
  --font: rgb(97, 97, 97);
  --navbarmobile: white
  
}

[data-theme="custom-dark"] {
  --background: rgb(35, 39, 42);
  --header: #414141;
  --card: #5f5f5f;
  --cardTitle: white;
  --font: rgb(223, 223, 223);
  --navbarmobile: #1d1d1d;
}

* {
  color: var(--font);
}

body{
  background: var(--background);
}

html::-webkit-scrollbar {
  width: 10px;
} /* this targets the default scrollbar (compulsory) */

html::-webkit-scrollbar-track {
  background-color: #eea159;
  margin-top: -5px;
  margin-bottom: -5px;
}

html::-webkit-scrollbar-thumb {
  background-color: #e67015;
  border-radius: 20px;
  height: 90px;
}

.btn{
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.btn-info {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.form-control:focus, .form-select:focus{
  border-color: #ec470000 !important;
  box-shadow: 0 0 0 .15rem #ec470048 !important;
}

input, select, textarea{
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  -webkit-border-radius:10px;
}


/*------------------------------------------------------*/

.navbar {
  width: 100%;
  padding-top: 10px;
  background: var(--header);
}

.navbar-brand {
  margin-left: 3%;
}

.navbar-brand > i {
  cursor: pointer;
}

.navbar-brand .menu {
  font-size: 40px;
  padding: 25px;
  color: #ffffff;
}

.navbar-brand .title {
  color: #ffffff;
  margin-top: 19%;
}

.navbar .nav-item{
  cursor: pointer;
}

.offcanvas{
  width: 22%;
}

.offcanvas *{
  color: rgb(57, 57, 57) !important;
}

.offcanvas img{
  margin-right: 15px;
  width: 25px;
}

.offcanvas span{
  font-size: 1rem;
  font-weight: 500;
}

.offcanvas span::after{
  float: right;
  margin: 12px;
}

.offcanvas ul{
  margin-top: 50px;
}

.offcanvas li{
  border-bottom: 1px solid #3939392e;
}

.offcanvas li:hover .icon{
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.offcanvas .dropdown-menu{
  padding-top: 0 !important;
  border: none !important;
}

.offcanvas .dropdown-menu li{
  border: none !important;
}

.offcanvas .btn-close{
  position: absolute;
  right: 0;
  padding: 20px;;
}

.navbar-collapse{
  flex-direction: row-reverse;
  margin-right: 5%;
  flex-basis: revert;
}

.navbar-collapse>ul{
  flex-direction: row;
  float: right;
}

.notification {
  text-decoration: none;
  padding: 1em 2em 0 0;
  position: relative;
  display: inline-block;
}

.notification .fa-envelope-open-text{
  font-size: 1.5em;
  color:white;
}

.notification .badge {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 3px 5px;
  border-radius: 50%;
  background: white;
  color: darkorange;
}

.avatar img.rounded-circle {
  width: 2.5em !important;
  height: 2.5em;
  object-fit: cover;
}

.avatar .dropdown-menu {
  left: auto;
  right: 0;
  position: absolute ;
}

@media only screen and (max-width: 1200px) {
  .offcanvas{
    width: 40%;
  }

  .offcanvas span{
    font-size: 1.2rem;
  }

}

@media only screen and (max-width: 767px) {
  .navbar{
    padding: 0rem 0.5rem;
  }

  .navbar-nav {
    flex-direction: row;
    float: right;
  }

  .navbar-brand {
    margin-top: 5px;
    margin-left: 0;
    width: auto;
    float: left;
    font-size: 1.10rem;
  }

  .navbar-brand .menu {
    font-size: 35px;
    padding: 0;
    margin: 15% 15px 0 10px;
    float: left !important;
  }

  .offcanvas{
    width: 82%;
  }

  .offcanvas span{
    font-size: 0.9rem;
  }

  .offcanvas .navbar-nav{
    flex-direction: column;
    float: none;
  }

  .navbar-collapse {
    flex-basis: 0 !important;
    margin-right: 0;
  }

  .notification {
    margin-top: 2%;
  }

  .avatar img.rounded-circle {
    width: 2.2em !important;
    height: 2.2em;
    margin-top: 3px;
  }

  .navbar-nav .offcanvas .dropdown-menu {
    position: inherit;
    float: none;
  }
}

/*------------------------------------------------------*/

.banner {
  margin-top: -0.30rem !important;
  padding: 1% 0 4% 8%;
  color: white !important;
  font-weight: 300 !important;
  z-index: 1;
  background: var(--header);
}

.banner *{
  color: white;
}

.banner .user {
  
  color: white;
}

.registros {
  padding: 1.2% 8% 0 10%;
}

.registros .btn {
  width: 37%;
  margin: 7px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-box-shadow: 6px 4px 13px -2px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 6px 4px 13px -2px rgba(0, 0, 0, 0.23);
  box-shadow: 6px 4px 13px -2px rgba(0, 0, 0, 0.23);
}

.registros .fas {
  color: white;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {

  .banner {
    padding: 4% 0 15% 6%;
  }

  .banner h1{
    font-size: 2.2rem;
  }

  .registros {
    display: none;
  }
  
}

/*------------------------------------------------------*/





/*------------------------------------------------------*/

.main {
  margin: -2.5% 8% 0 8%;
  z-index: 2;
}

.main .day {
  position: relative;
}

.main .card{
  background: var(--card);
  margin-bottom: 20px;
  box-shadow: 11px 16px 10px -12px rgba(0,0,0,0.1);
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  
}

.main .card .avatar-title i {
  font-size: 20px;
  color: rgba(255, 165, 91, 0.624);
}

.main .card h1 {
  color: var(--cardTitle);
  font-size: 1.8vw !important;
  font-weight: 500 !important;
}

.main .card .Presencial {
  color: #1d9b60;
  font-size: 1.8vw !important;
}

.main .card .Teletrabajo {
  color: #17a2b8;
  font-size: 1.8vw !important;
}

.main .card .Viaje {
  color: #ffc107;
  font-size: 1.8vw !important;
}

.main .card .Desconectado {
  color: #dc3545;
  font-size: 1.8vw !important;
}

.main .card .text-dark {
  font-weight: 700;
  color: #000000
}

.main .card .text-success {
  font-weight: 700;
}

.main .day .btn{
  margin-bottom: 15px;
}

.main .btn-default {
  width: 100%;
  margin-top: 10px;
  border: 3px solid #e9e9e9;
  background: #e9e9e9 !important;
}

.btn-default {
  outline: none !important;
  box-shadow: none;
  box-shadow: none;
}

.btn-default:focus {
  border: 3px solid #0000004a;
  box-shadow: none;
}

.main .fa-user {
  color: green;
}

.main .fa-check {
  color: #ffc107;
}

.main .fa-file-pdf {
  color: rgb(255, 255, 255);
  margin-right: 20px;
  font-size: 20px;
}

.main .fa-mobile-alt{
  color: rgb(255, 255, 255);
  margin-right: 20px;
  font-size: 20px;
}

.main .btn-danger {
  margin-top: 12px;
  width: 100%;
}

.main .install {
  width: 100%;
  margin-top: 11px;
  color: white;
  background: #f38823;
}

.main .fa-clipboard {
  color: #ffc107;
}

.fa-user {
  margin-right: 20px;
  font-size: 18px;
}

.fa-check, .fa-clipboard {
  margin-right: 15px;
  font-size: 18px;
}

.monthSelector {
  position: absolute;
  z-index: 2;
  right: 5%;
  top:1rem !important;
}

.monthSelector i {
  font-size: 1.2rem;
  color: rgb(255, 165, 91);
}

.monthSelector i:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  color: rgb(255, 119, 7);
}

.monthSelector .fa-search {
  margin-right: 20px;
}

.monthSelector .fa-chevron-left {
  margin-right: 10px;
}

.table {
  margin-bottom: 5%;
}

.table>:not(:first-child) {
  border-top: 1.5px solid rgba(203, 203, 203, 0.783);
}

.table th,td{
  text-align: center;
}

.clarification {
  text-align: right;
  padding-right: 9%;
}

.clarification p {
  color: rgb(167, 165, 165);
  weight: 100;
  font-size: 14px;
}

.clarification strong {
  color: rgb(167, 165, 165);
  weight: 200;
  font-size: 14px;
}

.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  z-index: 1 !important;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  max-width: 450px;
  margin-left: 20px;
  background-color: rgb(211, 211, 211);
  color: rgb(60, 60, 60);
  padding: 5px 5px;
  border-radius: 0.25rem;
  border: 2px solid rgb(202, 202, 202);
  /*Positionthetooltiptext-seeexamplesbelow!*/
  position: absolute;
  z-index: 1;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext hr {
  margin-top: 5px;
  margin-bottom: 5px;
  background: white;
}

@media only screen and (max-width: 1500px) {
  .main {
    margin: -2.5% 4% 0 4%;
  }

  .main .day .btn-danger,.btn-default {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .main {
    margin: -12% 4% 0 4%;
  }

  .main .card{
    box-shadow: 0px 15px 8px -12px rgba(0,0,0,0.1);
  }

  .main .card h1{
    font-size: 2.5rem !important;
  }

  .main .card .Desconectado {
    font-size: 2.5rem !important;
  }

  .main .card .Presencial{
    font-size: 2.5rem !important;
  }
  
  .main .card .Teletrabajo {
    font-size: 2.5rem !important;
  }

  .main .card .Viaje {
    font-size: 2.5rem !important;
  }

  .main .btn-default, .install{
    margin-bottom: 15px;
  }

  .main .install{
    margin-top: 14px;
  }

  .monthSelector{
    right: 7%;
  }

  .table th {
    width: 25%;
    font-size: smaller;
  }

  .table td,
  .table th {
    padding: 0.4rem;
    text-align: center;
  }

  .tooltip .tooltiptext {
    margin-left: 2px;
    min-width: 140px;
  }

}

/*------------------------------------------------------*/

.Toastify__toast{
  background: #28a745 !important;
  color: white !important;
  font-size: 18px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.Toastify__toast i {
  margin-left: 20px;
  color: white !important;
}

.Toastify__toast span {
  color: white !important;
  margin-left: 20px;
}

.Toastify__close-button>svg{
  color: white !important;
  fill: white;
}

@media only screen and (max-width: 767px) {
  .Toastify__toast-container {
    width: 96%;
    margin: 2%;
  }

  .Toastify__toast-container--bottom-right {
    right: 0; 
  }
}


/*------------------------------------------------------*/


.modal-route {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 3%;
  background: rgba(0,0,0,.75);
  color: gray;
  z-index: 3;
  overflow: auto;
}

.modal-route .modal{
  display: block;
  position: relative;
  background-color: white;
  animation: slideInDown;
  animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  
}

.modal-route .modal-content{
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}


/*------------------------------------------------------*/

.loader-container{
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

.loader-container>div{
  width: 20%;
  height: 95%;
  margin: auto;
  animation: zoomIn;
  animation-duration: 1s;
}

@media only screen and (max-width: 767px) {
  .loader-container>div{
    width: 80%;
    height: 95%;
  }
}

/*------------------------------------------------------*/

.alert-content {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.323);
  width: 100%;
  height: 100%;
  z-index: 999;
}

.alert{
  position: absolute;
  bottom: 0;
  width: 25%;
  margin: 2% 35%;
  animation: fadeInUp;
  animation-duration: 0.7s;
}

@media only screen and (max-width: 767px) {
  .alert{
    width: 80%;
    margin: 10%;
  }
  
}

/* ------------------------------------------------------ */

.react-datepicker{
  border: none !important;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.react-datepicker-wrapper {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}


.react-datepicker__header {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.react-datepicker-wrapper input[type="text"]:focus {
  outline: none;
  box-shadow: 0 0 0 .15rem #ec470048 !important;
}

/*------------------------------------------------------*/

.css-13cymwt-control{
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

/*------------------------------------------------------*/

.object-fit-cover {
  object-fit: cover;
}


/* ------------------------------------------------------ */

.floating-btns{
  position: fixed;
  bottom: 40px;
  right: 40px;
  height: 200px;
  width: 65px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 2;
}

.floating-btns:hover label:nth-child(1){
  bottom: 140px;
  cursor: pointer;
  background: #50c08c;
  
}

.floating-btns:hover label:nth-child(2){
  bottom: 70px;
  cursor: pointer;
  background: #50c08c;
}


.floating-btns label{
  position: absolute;
  bottom: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px #8f8f8f;
  background: rgb(58, 143, 103);
  transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
}

.floating-btns i{
  font-size: 25px;
  color: white;
}

.floating-btns input{
  display: none;
}

.rounded-end-0{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@media only screen and (max-width: 767px) {

  .floating-btns{
    bottom: 12vh;
    right: 5vw;

  }
}


/* ------------------------------------------------------ */

.navbarmobile {
  display: none;
}

@media only screen and (max-width: 767px) {

  .navbarmobile {
    display: inline;
    position: fixed;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    z-index: 2;
    -webkit-box-shadow: -1px 2px 17px 1px rgba(0, 0, 0, 0.31);
    -moz-box-shadow: -1px 2px 17px 1px rgba(0, 0, 0, 0.31);
    box-shadow: -1px 2px 17px 1px rgba(0, 0, 0, 0.31);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

  .navbarmobile .android{
    margin-bottom: auto;
  }

  .navbarmobile .ios{
    margin-bottom: 15px;
  }

  .navbarmobile .btn {
    width: 45%;
    margin: 3.5% 2.5% 3% 2.5%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

  .navbarmobile .fas {
    color: white;
    margin-right: 15px;
  }
  
}


@media (prefers-color-scheme: dark) {
  /* Dark mode */
}