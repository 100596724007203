.timeControl{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.timeControl .modal-body{
  margin: 10px 25px;
}

.timeControl .fa-pen-to-square{
  margin-right: 20px;
  font-size: 22px;
}

.timeControl .add-more{
  margin-top:1.5%;
}

.timeControl .add-more .btn-danger{
  background-color: #dc3545;
}

.timeControl .react-datepicker__day--highlighted{
  background: rgb(130, 130, 130);
}

.timeControl .react-datepicker__day--in-range{
  background: var(--header)
}

.timeControl .connection-error{
  width: 70px;
  margin: 0 auto;
  opacity: 0.7;
}


@media only screen and (max-width: 767px) {

  .react-datepicker{
    display: grid;
  }
  

}

