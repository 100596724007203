.Incidence{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.Incidence .col-md-9 {
  padding: 0 3%;
  border-left: 1px solid #e4e4e4;
}

.stretchs {
  margin-top: 25px;
  margin-left: 5px;
}

.add-more {
  text-align: center;
  margin-top: 6%;
  margin-bottom: 2%;
}

.add-more .fa-plus,
.fa-minus {
  margin-right: 5px;
}

.add-more button {
  background-color: #e4e4e4;
  font-size: 12px;;
  margin-right: 7px;
  margin-left: 7px;
}

.incidence .modal-footer .btn-danger{
  margin-right: 2.5%;
}

.modal-footer .btn-custom{
  background: #e4e4e4;
}

.modal-footer i{
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .Incidence{
    min-height: auto;
  }

  .Incidence .col-md-9 {
    margin-top: 20px;
  }
  
  .stretchs .form-group{
    margin-bottom: 15px;
  } 
  

  .add-more {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  
  .modal-footer{
    align-items: normal;
  }

  .modal-footer .btn-danger{
    margin-bottom: .25rem;
  }

}