/*=============== Login =====================*/

.logo {
  position: absolute;
  left: 3%;
  top: 5%;
  width: auto;
}

#login {
  padding-top: 15%;
  height: 100vh;
  background-image: url(/src/assets/image/BackgroundLogin.jpeg);
}

#login hr {
  background: white;
  opacity: 1;
}

#login .form-wrap {
  width: 35%;
  margin: 0 auto;
}
#login h1 {
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}
#login form {
  margin-top: 40px;
}
#login .form-group {
  margin-bottom: 25px;
}
#login .checkbox {
  margin-bottom: 40px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#login .checkbox.show:before {
  content: "\e013";
  color: #1fa67b;
  font-size: 17px;
  margin: 1px 0 0 3px;
  position: absolute;
  pointer-events: none;
  font-family: "Glyphicons Halflings";
}
#login .checkbox .character-checkbox {
  margin-right: 15px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #ccc;
  vertical-align: middle;
  display: inline-block;
}
#login .checkbox .label {
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
}
#login .btn.btn-custom {
  font-size: 14px;
  margin-bottom: 20px;
}
#login .forget {
  font-size: 13px;
  text-align: center;
  display: block;
}
#login .btn-custom {
  color: #fff;
  background: #eea159;
  -webkit-box-shadow: 18px 28px 33px -6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 18px 28px 33px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 18px 28px 33px -6px rgba(0, 0, 0, 0.3);
  width: 60%;
  margin: 0% 20% auto;
}
#login .btn-custom:hover,
.btn-custom:focus {
  color: #fff;
  outline: none !important;
  box-shadow: none;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-box-shadow: 18px 28px 32px -6px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 18px 28px 32px -6px rgba(0, 0, 0, 0.39);
  box-shadow: 18px 28px 32px -6px rgba(0, 0, 0, 0.39);
}

.develop {
  position: absolute;
  bottom: 2%;
  left: 0;
  color: white;
  text-align: center;
  width: 100%;
}

.modal-content {
  animation: slideInDown;
  animation-duration: 0.7s;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.425);
}

.modal-backdrop.show {
  opacity: 1 !important;
}

@media only screen and (max-width: 1500px) {
  #login {
    padding-top: 12%;
  }

  #login .form-wrap {
    width: 35%;
  }
}

@media only screen and (max-width: 767px) {
  .logo {
    display: none;
  }

  #login .form-wrap {
    width: 70%;
    margin: 27% auto;
  }
}

