.timeControlDpto{
  background-color: white;
  width: 60%;
  margin: 0 auto;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.timeControlDpto .modal-body{
  margin: 10px;
  padding-bottom: 25px;
}

.timeControlDpto .col-md-3{
  border-right: 1px solid #80808052;
}

.timeControlDpto .col-md-9{
  margin-top: -10px;
  padding-left: 50px;
}

.timeControlDpto  .react-datepicker-wrapper {
  border: 1px solid #80808052;
  padding: 5px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.timeControlDpto  .react-datepicker-wrapper input[type="text"] {
  border: none;
  color: rgb(34, 34, 34);
  width: 100%;
  background-color: transparent;
}

.timeControlDpto  .react-datepicker-wrapper input[type="text"]:focus {
  outline: none;
}

.timeControlDpto .rdt_TableHead {
  display: flex !important;
}

.timeControlDpto .connection-error{
  width: 70px;
  margin: 0 auto;
  opacity: 0.7;
}


@media only screen and (max-width: 767px) {
  .timeControlDpto{
    width: 100%;
  }
}