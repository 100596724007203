.workerCalendar{
  max-width: 70%;
  margin: 0 auto;
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.workerCalendar .modal-header i{
  margin-right: 20px;
}

.workerCalendar .modal-body{
  margin: 25px;
  position: inherit;
}

.workerCalendar .inputs{
  margin-bottom: 40px;
  display: flex;
  gap: 20px;
}

.workerCalendar .calendar {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 25px;
  
}

.workerCalendar .calendar h3 {
  text-align: center;
  margin-right: 50px;
  margin-bottom: 20px;
  
}

.workerCalendar .calendar h5 {
  margin-top: 20px;
}

.workerCalendar .month {
  display: grid;
  grid-template-columns: repeat(7, 50px);
  min-height: 250px;
}

.workerCalendar .day-name{
  text-align: center;
  margin-bottom: 10px;
  font-weight: 500;
}

.workerCalendar .day{
  padding: 5px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  
}

.workerCalendar .total{
  margin-top:40px;
  margin-bottom: 40px;
}

.workerCalendar .btn-outline-warning{
  background: #ffea005c ;
}

.workerCalendar .btn-outline-warning:hover{
  color: #ffc107 ;
}


@media only screen and (max-width: 767px) {
  .workerCalendar{
    max-width: 100%;
  }

  .workerCalendar .modal-body{
    margin: 20px 0 0 0 ;
  }

  .workerCalendar .inputs{
    flex-wrap: wrap;
    gap: 10px;
  }

  .workerCalendar .inputs .btn{
    font-size: 0.8rem;
  }

  .workerCalendar .month {
   
    grid-template-columns: repeat(7, 14%);
    
    
  }

  
  
 }