.newEmployee{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  width: 35%;
  margin: auto;
}

.newEmployee .fa-list-ul{
  margin-right: 20px;
  font-size: 22px;
}

.newEmployee .btn-default {
  background:#e67015 !important;
  color: white;
  width: 25%;
  margin: 6% 0 0 40% ;
}

@media only screen and (max-width: 1500px) {
  .newEmployee{
    width: 45%;
  }
}

@media only screen and (max-width: 768px) {
  .newEmployee{
    width: 95%;
  }

  .newEmployee .btn-default {
    background:#e67015 !important;
    color: white;
    width: 55%;
    margin: 6% 0 0 20% ;
  }

}

