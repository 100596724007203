.workCalendar{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.workCalendar .modal-header i{
  margin-right: 20px;
}

.workCalendar .modal-body{
  margin: 25px;
  position: inherit;
}

.workCalendar .col-md-3{
  padding-top: 10px;
  padding-right: 50px;
  border-right: 1px solid #ccc;
}

.workCalendar .saved-calendars> div {
  width: 100%;
  border: 1px solid #ccc;
  overflow: auto;
  padding: 10px;
  padding-left: 20px;
  border: none;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  background: rgba(1, 82, 1, 0.112);
  
}

.workCalendar .saved-calendars> div > p {
  max-width: 60%;
  float: left;
  margin-block-start: 0.35rem;
  margin-block-end: 0;
  color: #043c22;
  font-weight: 600;
}

.workCalendar .saved-calendars .fa-trash{
  font-size: 14px;
}

.workCalendar .inputs{
  margin: 0 3% 40px 5%;
}

.workCalendar .calendar {
  margin-left: 5%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 25px;
  
}

.workCalendar .calendar h3 {
  text-align: center;
  margin-right: 50px;
  margin-bottom: 20px;
  
}

.workCalendar .calendar h5 {
  margin-top: 20px;
}

.workCalendar .month {
  display: grid;
  grid-template-columns: repeat(7, 50px);
  min-height: 250px;
}

.workCalendar .day-name{
  text-align: center;
  margin-bottom: 10px;
  font-weight: 500;
}

.workCalendar .day{
  padding: 5px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  
}

.workCalendar .total{
  margin-bottom: 40px;
}

.workCalendar .btn-outline-warning{
  background: #ffea005c ;
}

.workCalendar .btn-outline-warning:hover{
  color: #ffc107 ;
}

.workCalendar .css-wsp0cs-MultiValueGeneric{
  max-width: 110px !important;
  
}

.workCalendar .css-t3ipsp-control{
  border-color: hsl(0, 0%, 80%) !important;
  box-shadow: none !important;
}

.workCalendar .css-13cymwt-control:hover{
  border-color: hsl(0, 0%, 80%) !important;
}


#workCalendar-modal.disabled{
  display: none;
}

#workCalendar-modal{
  position: absolute;
  background: #00000059;
  animation-duration: inherit;
}

#workCalendar-modal input[type="color"]{
  height: 38px;
}

#workCalendar-modal .more{
  width: fit-content;
  margin: 30px auto ;
}

#workCalendar-modal .more button{
  background-color: #e4e4e4;
}




@media only screen and (max-width: 767px) {

  
 }