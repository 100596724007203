.projectList{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.projectList .fa-list-ul{
  margin-right: 20px;
  font-size: 22px;
}

.projectList .rdt_Table{
  width: 100% !important;

}

.projectList .rdt_TableHead {
  display: inline-block !important;
}

.projectList .fa-trash-can{
  margin-left: 55%;
  font-size: 17px;
  color: #c90c0c;
}



