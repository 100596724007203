.notifications{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.notifications .modal-header i{
  
  margin-right: 10px;
}

.notifications .nav {
  display: block;
}

.notifications .notification {
  background-color: rgba(72, 72, 72, 0.433);
  width: 100%;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: white;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

}

.notifications .card{
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.notifications .card-header .avatar{
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  float: left;
}

.notifications .card-header p{
  font-size: 14px;
  margin-top: 0.5rem;
  margin-left: 20%;
  width: 100%;
  font-size: 15px;
  margin-bottom: 0;
  margin-block-end: 0;
}

.notifications .card-header span{
  font-size: 14px;
  border-radius:30px;
  -moz-border-radius:30px;
  -ms-border-radius:30px;
  -o-border-radius:30px;
  -webkit-border-radius:30px;
}

.notifications .Vacaciones{
  border: 1px solid #FFC107;
  background: #ffc1072d;
  color: #FFC107;
}

.notifications .Vacaciones:hover{
  color: #FFC107;
}

.notifications .Asuntos_propios{
  border: 1px solid #0d6efd;
  background: #0d6dfd2b;
  color: #0d6efd;
}

.notifications .Asuntos_propios:hover{
  color: #0d6efd;
}

.notifications .Error_fichaje{
  border: 1px solid #F44336;
  background: #f4433625;
  color: #F44336;
}

.notifications .Error_fichaje:hover{
  color: #F44336;
}

.notifications .Error_app{
  border: 1px solid #F44336;
  background: #f4433625;
  color: #F44336;
}

.notifications .Error_app:hover{
  color: #F44336;
}

.notifications .Enfermedad{
  border: 1px solid #747474;
  background: #2525252d;
  color: #747474;

}

.notifications .card-body p {
  margin-bottom: 0;

}

.notifications a {
  outline: none !important;
  text-decoration: none;
}


.tab-content {
  margin: 15px 0px 1% 2%;
  border-left: 1px solid rgb(226, 226, 226);
  width: 90%;
}

.content {
  padding-left: 6%;
  background: white !important;
}

.content .icon-calendar{
  max-width: 70px;
}

.stretchs {
  margin-top: 20px;
  margin-left: 0;
}

.notifications .modal-footer {
  border-top: none;
}

.modal-footer p {
  margin: .375rem 2rem;
}

.modal-footer .btn {
  margin: 0;
  margin-left: 0.45rem;
}

.modal-footer 
.btn-custom:focus{
  box-shadow: none;
  color: #191c1f;
  transform: scale(1);
  border: 3px solid rgba(128, 128, 128, 0.291);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

@media only screen and (max-width: 767px) {
  
  .notifications .notification{
    width: auto;
  }

  .notifications .card-header{
    padding: 5px
  }

  .notifications .col-xs-4{
    width: 45%
  }

  .notifications .col-xs-5{
    width: 55%
  }

  .notifications .card-header img{
    margin-top: 0.7rem;
  }

  .notifications .card-header p{
    margin-left: 50px;
  }

  .notifications .card-header span{
    font-size: 12px;
    margin: 0.75rem 0.2rem;
    float: right !important;
  }

  .tab-content {
    padding: 7% 2% 1% 2%;
    border-left: none;
    width: 96%;
  }

  .content {
    padding: 0;
  }

  .modal-footer{
    justify-content: center;
  }

  .modal-footer p {
    width: 100%;
    padding: 0;
    text-align: center;
    
  }

  .modal-footer .btn {
    margin-top: 3%;
    padding: 0.375rem 1.5rem;
  }


}