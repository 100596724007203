.workerRecords{
  background-color: white;
  animation: slideInUp;
  /*animation: fadeInDown;
  */animation-duration: 0.4s;
  -webkit-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  box-shadow: -1px 17px 38px -25px rgba(0,0,0,0.75);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.workerRecords .modal-body{
  margin: 25px;
}

.workerRecords form{
  width: 85%;
}

.workerRecords td.Presencial {
  color: #28a745;
}

.workerRecords td.Teletrabajo {
  color: #17a2b8;
}

.workerRecords td.Viaje {
  color: #ffc107;
}

@media only screen and (max-width: 767px) {

  .workerRecords .modal-body{
    margin: 5px;
  }

  .modal-route .workerRecords .col-md-4 {
    margin-bottom: 20px;
  }

  .workerRecords .col-md-4 .row{
    --bs-gutter-x: 0;
  }

  .workerRecords form{
    width: 100%;
  }
 }